import React, {Component} from 'react';

class NotFound extends Component {
  render() {
    return (
      <h2>Page not found!</h2>
    );
  }
}

export default NotFound;